export const stackLeft = {
  w: "60vw",
  bg: "white",
  borderRadius: "md",
  alignItems: "center",
  spacing: "5vh",
  zIndex: 1,
};

export const stackRight = {
  w: "40vw",
  h: "100vw",
  bg: "#F8EEDE",
  rounded: "sm",
  zIndex: "1",
};

export const loginBox = {
  position: "absolute",
  shadow: "dark-lg",
  zIndex: "2",
  bg: "#353935",
  color: "#353935",
  mt: "10vh",
  ml: "55vw",
  w: "35vw",
  borderRadius: "md",
  p: "2vw",
  mr: "2vw",
  height: "80vh",
};

export const loginText = {
  fontSize: "3xl",
  fontWeight: "bold",
};

export const gButtBox = {
  alignItems: "center",
  justifyContent: "center",
  pt: "10vh",
  pb: "12vh",
  height: "100%",
};

export const gButtContent = {
  p: "4",
  borderWidth: "1px",
  borderRadius: "md",
  shadow: "md",
  display: "flex",
  flexDirection: "column", // Stack content vertically
  alignItems: "center", // Center contents horizontally
};

export const defaultButton = {
  size: "lg",
  bg: "#93d7bf",
  color: "#353935",
  _hover: { bg: "#5e917f" },
};

export const apiURL = "https://api.maplefrenzy.com";
